ion-badge.profile-badge {
  --padding-start: 6px;
  --padding-end: 6px;
  min-width: 28px;
  border-radius: 10px;
  margin: 0 4px 0 14px;
}
h4.page-subheader {
  margin-left: 12px;
}
div.profile-stat {
  display: flex;
  align-items: center;
  padding: 4px 0;
}
