ion-avatar {
  margin: 0 4px 0 4px;
}
ion-avatar.thread, ion-avatar.thread ion-img {
  height: 2.1em;
  width: 2.1em;
  cursor: pointer;
}
ion-avatar.menu, ion-avatar.menu ion-img {
  height: 24px;
  width: 24px;
}
ion-avatar.update-card, ion-avatar.update-card ion-img {
  height: 2.1em;
  width: 2.1em;
  cursor: pointer;
}
ion-avatar.in-popover, ion-avatar.update-card {
  margin: 0 8px 0 4px;
}
