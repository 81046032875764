.emoji-larger {
  font-size: 150% !important;
}
ion-range {
  --knob-size: 24px;
  --knob-background: #fff;
  --knob-box-shadow:
    0 3px 1px rgba(0,0,0,0.1),
    0 4px 8px rgba(0,0,0,0.13),
    0 0 0 1px rgba(0,0,0,0.02);
}
ion-grid.pct-block {
  padding: 0;
}
