ion-badge.badge-more {
  position: relative;
  top: 0.8em;
  right: 0.8em;
}
ion-img.rounded {
  border-radius: 5%;
}
ion-badge.badge-hide {
  visibility: hidden;
}
