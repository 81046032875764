.active-marker {
  opacity: 0.7;
}
.counter-span {
  top: 0.5em;
}
.builds-counter {
  background: var(--ion-color-medium);
  font-size: 250%;
}
.notifications-counter {
  background: var(--ion-color-danger);
  font-size: 250%;
}

#header-logo {
  height: 50px;
}
