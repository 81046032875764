ion-modal.auto-height {
    &.bottom {
        align-items: flex-end;
    }

    --height: auto;
    --width: 94%;

    .ion-page {
        position: relative;
        display: block;
        contain: content;

        .modal-content {
            max-height: 80vh;
            overflow: auto;
            padding: 10px;
        }
    }
}

a.user-link {
  font-weight: bold;
  color: inherit;
  text-decoration: none;
  border-bottom-color: var(--color);
  border-bottom-width: 1px;
  border-bottom-style: dashed;
}
span.user-nolink {
  font-weight: bold;
}

ion-img.orient-7 {
  transform: rotate(90deg);
}
ion-badge.stat-badge {
  --padding-start: 6px;
  --padding-end: 6px;
  min-width: 28px;
  border-radius: 10px;
  margin: 0 4px 0 14px;
}
div.stat-badge-container {
  display: flex;
  align-items: center;
  padding: 4px 0;
}
div.div-comment {
  display: flex;
  float: right;
}
ion-img.rounded {
  border-radius: 5%;
  overflow: hidden;
}
ion-row.photo-grid-row ion-img {
  width: 100%;
  height: 100%;
  max-height:400px;
  object-fit: contain;
  object-position: left;
}
ion-row.photo-grid-row ion-col {
  max-height:400px;
}
ion-row.photo-grid-row ion-img.pg-multi {
  object-fit: cover;
  object-position: center;
}
