div.quote-content {
  background-color: var(--ion-color-medium-shade);
  color: var(--ion-color-medium-contrast);
  border-radius: 20px;
  padding: 5px 8px;
  display: inline-block;
}
div.quote-other {
  background-color: var(--ion-color-secondary-shade);
  color: var(--ion-color-secondary-contrast);
  border-radius: 20px;
  padding: 5px 8px;
  display: inline-block;
  vertical-align: top;
}
ion-text.end-quote {
  display: inline-block;
  font-style: italic;
  font-family: georgia, serif;
  font-size: 2em;
  position: absolute;
  bottom: -.3em;
  /* padding-left: 0;
  visibility: hidden;*/
}
ion-col.quote-mark {
  font-style: italic;
  font-family: georgia, serif;
  font-size: 2.5em;
  text-align: center;
  top: -0.4em;
}
ion-row.thread, ion-row.thread ion-col {
  padding-bottom: 0px;
}
ion-col.comment-row {
  text-align: right;
}
ion-col.comment-row ion-avatar {
  display: inline-block;
  vertical-align: top;
}
