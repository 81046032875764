.emoji-larger {
  font-size: 150%;
}

.avatar-center {
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}

ion-text.info-msg {
  font-size: 75%;
  padding-left: 0;
}

ion-select-popover ion-label {
  white-space: normal !important;
}
